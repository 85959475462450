import Vue from 'vue'
import App from './App.vue'
import {
  router
} from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import MetaInfo from 'vue-meta-info'

import './assets/css/normalize.css'
import './assets/css/animate.min.css'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)
Vue.use(MetaInfo)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + '-' + '英特沃德（武汉）医疗科技发展有限公司'
  next()
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
