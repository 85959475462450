<template>
  <div class="header"
       :class="{'checked': isChecked}"
       ref="contentbox"
       @mouseenter="headMouseover"
       @mouseleave="headMouseleave">
    <div class="inner">
      <router-link to="/"
                   tag="div"
                   class="logo">
        <img v-if="isChecked"
             src="../assets/images/logo2.png"
             alt="">
        <img v-else
             src="../assets/images/logo.png"
             alt="">
        <div class="text">
          <h1>英特沃德（武汉）医疗科技发展有限公司</h1>
          <p>Intelligent Ward (Wuhan) Medical Technology Development Co., Lt</p>
        </div>
      </router-link>
      <div class="nav">
        <ul>
          <router-link to="/index"
                       tag="li"
                       class="child">
            <a href="javascript:;">解决方案</a>
          </router-link>
          <li class="child"
              :class="{'router-link-active': isProduct}">
            <a href="javascript:;">产品中心</a>
            <div class="list"
                 style="overflow: hidden;">
              <!--class="animated slideInDown"-->
              <ul>
                <li>
                  <router-link to="/product/doctor"><em class="s1-icon"></em>病区医生工作中心与移动医疗系统</router-link>
                </li>
                <li>
                  <router-link to="/product/monitor"><em class="s2-icon"></em>病区24H监护中心与夜间病床管理系统</router-link>
                </li>
                <li>
                  <router-link to="/product/bedside"><em class="s3-icon"></em>病区床旁通用医护患终端中心系统</router-link>
                </li>
                <li>
                  <router-link to="/product/emr"><em class="s4-icon"></em>病区医嘱执行管理中心与EMR系统</router-link>
                </li>
                <li>
                  <router-link to="/product/unperturbed"><em class="s5-icon"></em>病区无扰式24H生命体征监护系统<br>与患者健康管理系统</router-link>
                </li>
                <li>
                  <router-link to="/product/health"><em class="s6-icon"></em>病区患者医疗辅助中心与健康教育系统</router-link>
                </li>
                <li>
                  <router-link to="/product/nurse"><em class="s7-icon"></em>病区护理工作中心与移动护理系统</router-link>
                </li>
                <li>
                  <router-link to="/product/infusion"><em class="s8-icon"></em>病区床旁自动输液站系统</router-link>
                </li>
                <li>
                  <router-link to="/product/his"><em class="s9-icon"></em>CDR数字病区医联体中心<br>与高端医养支持系统</router-link>
                </li>
                <li>
                  <router-link to="/product/ecg"><em class="s13-icon"></em>病区24H心电监护中心</router-link>
                </li>
                <!-- <li>
                  <router-link to="/product/ai"><em class="s10-icon"></em>AI学术课题研究中心</router-link>
                </li> -->
                <li>
                  <router-link to="/product/cdr"><em class="s11-icon"></em>CDR数字病区功能扩展中心与应用系统</router-link>
                </li>
                <li>
                  <router-link to="/product/data"><em class="s12-icon"></em>CDR数字病区系统支持系统与大数据中心</router-link>
                </li>
              </ul>
            </div>
          </li>
          <router-link to="/customers"
                       tag="li"
                       class="child">
            <a href="javascript:;">合作关系</a>
          </router-link>
          <router-link to="/news"
                       tag="li"
                       class="child">
            <a href="javascript:;">企业动态</a>
          </router-link>
          <router-link to="/job"
                       tag="li"
                       class="child">
            <a href="javascript:;">企业招聘</a>
          </router-link>
          <router-link to="/about"
                       tag="li"
                       class="child">
            <a href="javascript:;">关于我们</a>
          </router-link>
          <!-- <li>
            <router-link to="/product">产品中心</router-link>
          </li>
          <li><router-link to="/customers">合作关系</router-link></li>
          <li><router-link to="/job">企业招聘</router-link></li>
          <li><router-link to="/about">关于我们</router-link></li> -->
        </ul>
      </div>
    </div>
    <!--<div v-for="(item, index) in routes" :key="index">{{ item }}</div>-->
  </div>
</template>

<script>
// import { routes } from '../router'
export default {
  name: 'Header',
  data () {
    return {
      pageName: 'Home',
      isChecked: false,
      scrollTop: 0,
      isProduct: false
    }
  },
  // computed: {
  //   routes () {
  //     return routes
  //   }
  // },
  watch: {
    $route (to, from) {
      // 产品中心添加 ‘router-link-active’
      this.productAddClass(this.$route)
      this.pageName = to.name
      this.headIsChecked()
    }
  },
  mounted () {
    this.productAddClass(this.$route)
    this.pageName = this.$route.name
    this.headIsChecked()
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    productAddClass (router) {
      const str = router.matched[0].path.substring(1)
      str === 'product' ? this.isProduct = true : this.isProduct = false
    },
    handleScroll () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
      this.$emit('sendScrollTop', this.scrollTop)
      // console.log(this.scrollTop)
      if (this.pageName === 'Home') {
        if (this.scrollTop > 50) {
          this.isChecked = true
        } else {
          this.isChecked = false
        }
      }
    },
    headIsChecked () {
      if (this.pageName !== 'Home') {
        this.isChecked = true
      } else {
        this.isChecked = false
      }
    },
    headMouseover () {
      if (this.pageName === 'Home') {
        this.isChecked = true
      }
    },
    headMouseleave () {
      if (this.pageName === 'Home') {
        if (this.scrollTop > 50) {
          return false
        }
        this.isChecked = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  text-align: left;
  // background: #444;
  width: 100%;
  height: 76px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  // background: #A6A6A6;
  .inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .logo {
    width: 500px;
    display: flex;
    cursor: pointer;
    img {
      width: 60px;
      height: 59px;
      margin-right: 15px;
      margin-top: 10px;
    }
    .text {
      flex: 1;
      h1 {
        font-size: 20px;
        margin: 19px 0 13px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .nav {
    flex: 1;
    .child {
      display: block;
      float: left;
      margin: 0 18px;
      a {
        color: #fff;
        display: block;
        font-size: 18px;
        padding: 36px 3px 20px;
      }
      &.active,
      &:hover,
      &.router-link-active {
        border-bottom: 2px solid #00dcd4;
        a {
          color: #00dcd4;
        }
      }
      &:hover .list {
        display: block;
        animation: moverHeight 0.5s;
      }
      .list {
        display: none;
        width: 100%;
        height: 344px;
        background: #fff;
        position: absolute;
        top: 76px;
        left: 0;
        ul {
          width: 1200px;
          padding: 20px 0;
          margin: 0 auto;
          overflow: hidden;
          // display: flex;
          // flex-wrap: wrap;
          li {
            width: 400px;
            height: 76px;
            padding: 20px 30px 20px 10px;
            float: left;
            a {
              padding: 0;
              display: flex;
              // justify-content: center;
              align-items: center;
              display: flex;
              color: #6a6a6a;
              font-size: 16px;
              padding: 0;
              line-height: 18px;
            }
            a.router-link-active,
            a:hover {
              color: #00dcd4;
            }
            em {
              display: block;
              float: left;
              width: 36px;
              height: 36px;
              margin-right: 15px;
              background-repeat: no-repeat;
              background-position: center;
            }
            // span {
            //   display: block;
            //   color: #6A6A6A;
            //   font-size: 16px;
            //   padding: 0;
            //   line-height: 18px;
            // }
            .s1-icon {
              background-image: url("../assets/images/s1.png");
            }
            .s2-icon {
              background-image: url("../assets/images/s2.png");
            }
            .s3-icon {
              background-image: url("../assets/images/s3.png");
            }
            .s4-icon {
              background-image: url("../assets/images/s4.png");
            }
            .s5-icon {
              background-image: url("../assets/images/s5.png");
            }
            .s6-icon {
              background-image: url("../assets/images/s6.png");
            }
            .s7-icon {
              background-image: url("../assets/images/s7.png");
            }
            .s8-icon {
              background-image: url("../assets/images/s8.png");
            }
            .s9-icon {
              background-image: url("../assets/images/s9.png");
            }
            .s10-icon {
              background-image: url("../assets/images/s10.png");
            }
            .s11-icon {
              background-image: url("../assets/images/s11.png");
            }
            .s12-icon {
              background-image: url("../assets/images/s12.png");
            }
            .s13-icon {
              background-image: url("../assets/images/s13.png");
            }
          }
        }
      }
    }
  }
  &.checked {
    background: #fff;
    color: #00dcd4;
    animation: moverBg 0.3s;
    border-bottom: 1px solid rgba($color: #a6a6a6, $alpha: 0.5);
    .nav a {
      color: #434343;
    }
  }
}

@keyframes moverHeight {
  from {
    height: 0;
  }
  to {
    height: 344px;
  }
}

@keyframes moverBg {
  from {
    background: none;
  }
  to {
    background: #fff;
  }
}
</style>
