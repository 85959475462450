import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: {
      name: 'Home'
    }
  },
  {
    path: '/index',
    component: Home,
    redirect: {
      name: 'Home'
    },
    children: [{
      path: 'index',
      name: 'Home',
      component: () => import('../views/home/Index'),
      meta: {
        title: '解决方案'
      }
    }]
  },
  {
    path: '/product',
    component: Home,
    redirect: {
      name: 'Doctor'
    },
    children: [
      {
        path: 'doctor',
        name: 'Doctor',
        component: () => import('../views/product/Doctor'),
        meta: {
          title: '病区医生工作中心与移动医疗系统'
        }
      },
      {
        path: 'monitor',
        name: 'Monitor',
        component: () => import('../views/product/Monitor'),
        meta: {
          title: '病区24H监护中心与夜间病床管理系统'
        }
      },
      {
        path: 'bedside',
        name: 'Bedside',
        component: () => import('../views/product/Bedside'),
        meta: {
          title: '病区床旁通用医护患终端中心系统'
        }
      },
      {
        path: 'emr',
        name: 'Emr',
        component: () => import('../views/product/emr'),
        meta: {
          title: '病区医嘱执行管理中心与EMR系统'
        }
      },
      {
        path: 'unperturbed',
        name: 'Unperturbed',
        component: () => import('../views/product/unperturbed'),
        meta: {
          title: '病区无扰式24H生命体征监护系统与患者健康管理系统'
        }
      },
      {
        path: 'health',
        name: 'Health',
        component: () => import('../views/product/health'),
        meta: {
          title: '病区患者医疗辅助中心与健康教育系统'
        }
      },
      {
        path: 'nurse',
        name: 'Nurse',
        component: () => import('../views/product/nurse'),
        meta: {
          title: '病区护理工作中心与移动护理系统'
        }
      },
      {
        path: 'infusion',
        name: 'Infusion',
        component: () => import('../views/product/infusion'),
        meta: {
          title: '病区床旁自动输液站系统'
        }
      },
      {
        path: 'his',
        name: 'His',
        component: () => import('../views/product/his'),
        meta: {
          title: 'CDR数字病区医联体中心与高端医养支持系统'
        }
      },
      {
        path: 'ai',
        name: 'Ai',
        component: () => import('../views/product/ai'),
        meta: {
          title: 'AI学术课题研究中心'
        }
      },
      {
        path: 'ecg',
        name: 'EcgMonitor',
        component: () => import('../views/product/ecgMonitor'),
        meta: {
          title: '病区24H心电监护中心'
        }
      },
      {
        path: 'cdr',
        name: 'Cdr',
        component: () => import('../views/product/cdr'),
        meta: {
          title: 'CDR数字病区功能扩展中心与应用系统'
        }
      },
      {
        path: 'data',
        name: 'Data',
        component: () => import('../views/product/data'),
        meta: {
          title: 'CDR数字病区系统支持系统与大数据中心'
        }
      }
    ]
  },
  {
    path: '/customers',
    component: Home,
    redirect: {
      name: 'Customers'
    },
    children: [{
      path: 'index',
      name: 'Customers',
      component: () => import('../views/customers/Index'),
      meta: {
        title: '合作关系'
      }
    }]
  },
  {
    path: '/job',
    component: Home,
    redirect: {
      name: 'Job'
    },
    children: [{
      path: 'index',
      name: 'Job',
      component: () => import('../views/job/Index'),
      meta: {
        title: '企业招聘'
      }
    }]
  },
  {
    path: '/about',
    component: Home,
    redirect: {
      name: 'About'
    },
    children: [{
      path: 'index',
      name: 'About',
      component: () => import('../views/about/Index'),
      meta: {
        title: '关于我们'
      }
    }]
  },
  {
    path: '/news',
    component: Home,
    redirect: {
      name: 'News'
    },
    children: [
      {
        path: 'index',
        name: 'News',
        component: () => import('../views/news/Index'),
        meta: {
          title: '企业动态'
        }
      },
      {
        path: 'new_01',
        name: 'New_01',
        component: () => import('../views/news/new_01'),
        meta: {
          title: '助力军运会 英特沃德数字病区系统打造医疗保障新模式'
        }
      },
      {
        path: 'new_02',
        name: 'New_02',
        component: () => import('../views/news/new_02'),
        meta: {
          title: '武汉市长周先旺调研武汉软件新城'
        }
      },
      {
        path: 'new_03',
        name: 'New_03',
        component: () => import('../views/news/new_03'),
        meta: {
          title: '武汉市长周先旺调研武汉软件新城'
        }
      },
      {
        path: 'new_04',
        name: 'New_04',
        component: () => import('../views/news/new_04'),
        meta: {
          title: '干部病房三科（综合科）锻造健康管理服务的“医护天团”'
        }
      }
    ]
  }
]

// mode: 'history',
const router = new VueRouter({
  routes
})

export {
  router,
  routes
}
