<template>
  <div id="app" @contextmenu.prevent ondragstart="return false">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import "../src/assets/css/font.css";
@import "../src/assets/css/style.scss";
#app {
  width: 100%;
  min-width: 1200px;
  font-family: 'SourceHanSansCN-Regular', 'Microsoft YaHei', '微软雅黑', Helvetica, Arial, sans-serif;
  color: #434343;
  user-select: none;
}
</style>
