<template>
  <div id="home">
    <Header @sendScrollTop="getScrollTop" />
    <router-view :scrollTopNum="scrollTopNum" />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'

export default {
  name: 'home',
  metaInfo: {
    title: '英特沃德（武汉）医疗科技发展有限公司', // set a title
    meta: [{ // set meta
      name: '医疗系统开发，医疗大数据应用，软件系统研发与集成，医疗装备研发生产与集成，数字病区',
      content: '英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。'
    }],
    link: [{ // set link
      rel: 'next',
      href: 'http://www.ytwdwh.com/'
    }]
  },
  data () {
    return {
      scrollTopNum: 1
    }
  },
  methods: {
    getScrollTop (num) {
      this.scrollTopNum = num
      // console.log('a', num)
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>
