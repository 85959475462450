<template>
  <div class="footer">
    <div class="inner">
      <div class="item map">
        <div class="title">网站导航： </div>
        <ul>
          <li>
            <router-link to="/">解决方案</router-link>
          </li>
          <li>
            <router-link to="/product">产品中心</router-link>
          </li>
          <li>
            <router-link to="/customers">合作关系</router-link>
          </li>
          <li>
            <router-link to="/job">企业招聘</router-link>
          </li>
          <li>
            <router-link to="/about">关于我们</router-link>
          </li>
        </ul>
      </div>
      <div class="site">
        <div class="h1">英特沃德（武汉）医疗科技发展有限公司</div>
        <div class="dl"><span class="dt address">地址：</span><span class="dd">武汉市东湖高新技术开发区花城大道8号武汉软件新城2期C11栋7层</span></div>
        <div class="dl"><span class="dt email">邮箱：</span><span class="dd">ytwd@ytwdwh.com</span></div>
        <div class="dl"><span class="dt tel">电话：</span><span class="dd">027-65527829</span></div>
        <div class="code">
          <img src="../assets/images/code.png"
               alt="">
          <span>关注微信</span>
        </div>
      </div>
      <div class="item link">
        <div>友情链接： </div>
        <ul>
          <li><a href="http://www.nhc.gov.cn/"
               target="_blank">国家卫生健康委员会</a></li>
          <li><a href="http://wjw.wuhan.gov.cn/"
               target="_blank">武汉市卫生健康委员会</a></li>
          <!-- <li><a href="##">深圳市深迈医疗设备有限公司</a></li>
          <li><a href="##">广州视源电子科技股份有限公司</a></li>
          <li><a href="##">广州欧浦瑞医疗科技有限公司</a></li> -->
        </ul>
      </div>
    </div>
    <div class="copy"><a href="https://beian.miit.gov.cn"
         target="_blank">鄂ICP备19006529号-1</a></div>
    <div class="fixed-contactus">
      <div class="us"
           @mouseover="showUsDetail"
           @mouseleave="hideUsDetail">联系我们</div>
      <div class="us-detail"
           v-show="isUsDetail"
           @mouseover="clearUsTimer"
           @mouseleave="hideUsDetail">
        <div class="box">
          <p class="h4 icon i1">联系电话</p>
          <p>027-65527829</p>
        </div>
        <div class="box">
          <p class="h4 icon i2">在线客服</p>
          <p><a target="_blank"
               href="http://wpa.qq.com/msgrd?v=3&uin=2874403361&site=qq&menu=yes"><img border="0"
                   src="http://wpa.qq.com/pa?p=2:2874403361:51" /></a></p>
        </div>
        <div class="box"
             @click="openMsg">
          <p class=" icon i3">在线留言</p>
        </div>
      </div>
    </div>
    <div class="message"
         v-if="isMsg">
      <div class="title">
        留言板
        <span class="close"
              @click="closeMsg"></span>
      </div>
      <div class="from">
        <div class="input-item">
          <span class="label"><em>*</em>姓名：</span>
          <span class="input">
            <input type="text"
                   v-model.trim="from.name">
            <div class="tips"
                 v-show="nameTips">请输入您的姓名</div>
          </span>
        </div>
        <div class="input-item">
          <span class="label"><em>*</em>电话：</span>
          <span class="input">
            <input type="text"
                   v-model.trim="from.tel">
            <div class="tips"
                 v-show="telTips">请填写您的电话或手机号码</div>
          </span>
        </div>
        <div class="input-item">
          <span class="label"><em>*</em>留言：</span>
          <span class="input">
            <textarea name=""
                      v-model.trim="from.msg"></textarea>
            <div class="tips"
                 v-show="msgTips">留言内容不能为空</div>
          </span>
        </div>
        <button @click="submit">提交</button>
      </div>
      <!-- v-if="msgSuccess" -->
      <div class="msg-success"
           v-show="msgSuccess">
        <div class="icon"></div>
        <p class="p1">留言提交成功</p>
        <p class="p2">我们会尽快和您联系！</p>
        <span class="btn"
              @click="closeMsg">关闭（{{ time }}）</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      isMsg: false,
      from: {
        name: '',
        tel: '',
        msg: ''
      },
      nameTips: false,
      telTips: false,
      msgTips: false,
      msgSuccess: false,
      time: 3,
      isUsDetail: false,
      usTimer: null
    }
  },
  methods: {
    openMsg () {
      this.isMsg = true
      this.isUsDetail = false
    },
    closeMsg () {
      this.isMsg = false
      this.nameTips = false
      this.telTips = false
      this.msgTips = false
      this.from = {
        name: '',
        tel: '',
        msg: ''
      }
    },
    submit () {
      if (!this.from.name) {
        this.nameTips = true
        return false
      } else {
        this.nameTips = false
      }

      if (!this.from.tel) {
        this.telTips = true
        return false
      } else {
        this.telTips = false
      }

      if (!this.from.msg) {
        this.msgTips = true
        return false
      } else {
        this.msgTips = false
      }
      // console.log(this.from)
      this.msgSuccess = true
      var timer = setInterval(() => {
        this.time = this.time - 1
        if (this.time === 0) {
          clearInterval(timer)
          this.msgSuccess = false
          this.time = 3
          this.closeMsg()
        }
      }, 1000)
      // setTimeout(() => {
      //   this.msgSuccess = false
      //   this.closeMsg()
      // }, 3000)
    },
    showUsDetail () {
      this.clearUsTimer()
      this.isUsDetail = true
    },
    hideUsDetail () {
      this.usTimer = setTimeout(() => {
        this.isUsDetail = false
      }, 300)
    },
    clearUsTimer () {
      clearTimeout(this.usTimer)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  text-align: left;
  padding: 36px 0 26px;
  background-color: #434343;
  color: #fff;
  font-size: 16px;
  .inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .item {
    width: 300px;
    ul {
      margin-top: 46px;
      line-height: 36px;
    }
    a {
      color: #fff;
    }
  }
  .map {
    width: 320px;
    padding-right: 20px;
  }
  .link {
    width: 280px;
  }
  .site {
    width: 600px;
    padding-right: 240px;
    line-height: 28px;
    position: relative;
    .code {
      width: 100px;
      height: 100px;
      position: absolute;
      bottom: 35px;
      right: 200px;
      span {
        display: block;
        text-align: center;
        margin-top: -8px;
      }
    }
    .h1 {
      line-height: 24px;
      margin-bottom: 44px;
    }
    .dl {
      display: flex;
      margin-top: 18px;
      .dt {
        width: 75px;
        &::before {
          content: '';
          display: block;
          float: left;
          width: 22px;
          height: 22px;
          margin-top: 3px;
          margin-right: 5px;
          background-position: center;
          background-repeat: no-repeat;
        }
        &.address::before {
          background-image: url('../assets/images/address.png');
        }
        &.tel::before {
          background-image: url('../assets/images/tel.png');
        }
        &.email::before {
          background-image: url('../assets/images/email.png');
        }
      }
      .dd {
        flex: 1;
      }
    }
  }
  .copy {
    margin-top: 54px;
    text-align: center;
    a {
      color: #fff;
    }
  }
  .fixed-contactus {
    position: fixed;
    bottom: 60px;
    right: 10px;
    width: 40px;
    height: 120px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(56, 114, 201, 0.25);
    z-index: 1009;
    .us {
      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 33px;
        background: url('../assets/images/contactus.png') no-repeat center;
        background-size: contain;
      }
      cursor: pointer;
      width: 20px;
      height: 100%;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      color: #333;
    }
    .us-detail {
      display: block;
      position: absolute;
      right: 45px;
      bottom: 0;
      background: #fff;
      box-shadow: 0 2px 8px 0 rgba(56, 114, 201, 0.25);
      width: 140px;
      box-sizing: border-box;
      transition: all 0.6s linear 0s;
      border-radius: 5px;
      overflow: hidden;
      .box {
        color: #333;
        cursor: pointer;
        padding: 15px 5px;
        border-top: 1px dashed #ededed;
        .icon {
          padding-left: 20px;
          background-image: url('../assets/images/us-icon.png');
          background-repeat: no-repeat;
          &.i1 {
            background-position: 0 -26px;
          }
          &.i2 {
            background-position: 0 -52px;
          }
          &.i3 {
            background-position: 0 0;
          }
        }
        p {
          padding-left: 20px;
          a,
          img {
            display: block;
          }
        }
        .h4 {
          margin-bottom: 10px;
          // padding-left: 0;
        }
        // &:hover {
        //   background: #00dcd4;
        //   color: #fff;
        // }
        &:first-child {
          border-top: 0;
          cursor: default;
        }
      }
    }
  }
}
.message {
  position: fixed;
  width: 472px;
  bottom: 60px;
  right: 60px;
  z-index: 10;
  // border: 1px solid #ccc;
  box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.2);
  // border-radius: 5px;
  background: #fff;
  overflow: hidden;
  .title {
    background: #434343;
    font-size: 20px;
    font-weight: bold;
    line-height: 48px;
    padding-left: 20px;
  }
  .close {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    background: url('../assets/images/msg-close.png') no-repeat center;
    top: 14px;
    right: 14px;
    z-index: 10;
    cursor: pointer;
  }
  .from {
    padding: 30px 20px;
    .input-item {
      padding-bottom: 20px;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      .label {
        color: #434343;
        width: 60px;
        line-height: 40px;
        font-size: 16px;
      }
      em {
        color: red;
      }
      .input {
        flex: 1;
      }
      .tips {
        color: red;
        position: absolute;
        bottom: 0;
        left: 60px;
        line-height: 20px;
        font-size: 14px;
      }
      input {
        width: 100%;
        line-height: 18px;
        padding: 10px 8px;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        color: #434343;
      }
      textarea {
        width: 100%;
        line-height: 20px;
        padding: 8px;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        height: 122px;
        resize: none;
        outline: none;
        color: #434343;
      }
    }
    button {
      display: block;
      line-height: 36px;
      padding: 0 26px;
      border-radius: 5px;
      border: 0;
      margin: 0 auto;
      background: #00dcd4;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .msg-success {
    position: absolute;
    z-index: 11;
    background: #fff url('../assets/images/msg-bg.png') no-repeat center top;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 278px;
    text-align: center;
    .p1 {
      color: #434343;
      font-size: 24px;
    }
    .p2 {
      margin-top: 20px;
      color: #6a6a6a;
      font-size: 16px;
    }
    .btn {
      display: inline-block;
      background: #00dcd4;
      line-height: 32px;
      color: #fff;
      border-radius: 10px;
      width: 124px;
      font-size: 18px;
      margin: 40px auto 0;
      cursor: pointer;
    }
    .icon {
      background: #fff;
      position: absolute;
      top: 58px;
      right: 217px;
      animation: mover 0.8s;
    }
  }
}
@keyframes mover {
  from {
    width: 55px;
    height: 55px;
  }
  to {
    width: 0;
    height: 0px;
  }
}
</style>
